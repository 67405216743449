import Vue from 'vue';
import Router from 'vue-router';
import i18n, { loadLocaleMessagesAsync } from "@/i18n"
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from "@/util/i18n/document"

const Root = () => import(`./views/Root.vue`);
const Home = () => import(`./views/Home.vue`);
const Projects = () => import(`./views/Projects.vue`);
const Team = () => import(`./views/Team.vue`);
const Sponsor = () => import(`./views/Sponsor.vue`);
const Participate = () => import(`./views/Participate.vue`);
const About = () => import(`./views/About.vue`);

Vue.use(Router);

const { locale } = i18n

const routes = [
  {
    path: "/",
    redirect: locale,
  },
  {
    path: "/:locale",
    component: Root,
    children: [
      {
        path: "",
        name: `home`,
        component: Home,
      },
      {
        path: `projects`,
        name: `projects`,
        component: () =>
          import(/* webpackChunkName: "about" */ "./views/Projects.vue")
      },
      {
        path: `about`,
        name: `about`,
        component: About,
      },
      {
        path: `team`,
        name: `team`,
        component: Team,
      },
      {
        path: `sponsor`,
        name: `sponsor`,
        component: Sponsor,
      },
      {
        path: `participate`,
        name: `participate`,
        component: Participate,
      }
    ]
  }
]

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.params.locale === from.params.locale) {
    next()
    return
  }

  const { locale } = to.params
  loadLocaleMessagesAsync(locale).then(() => {
    setDocumentLang(locale)

    setDocumentDirectionPerLocale(locale)

    setDocumentTitle(i18n.t("app.title"))
  })

  next()
})

export default router
