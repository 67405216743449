const dateTimeFormats = {
  en: {
    short: { year: "numeric", month: "short", day: "numeric" }
  },
  no: {
    short: { year: "numeric", month: "short", day: "numeric" }
  },
  uk: {
    short: { year: "numeric", month: "short", day: "numeric" }
  },
}

export default dateTimeFormats
