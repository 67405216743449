<template>
    <nav class="navbar navbar-expand-sm navbar-light bg-light">
      <div class="container-fluid text-center">
        <a class="navbar-brand p-0" href="/">
          <img alt="B4U logo" src="../assets/logo.png" height="32px" width="32px"/>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <LocalizedLink to="/" class="nav-link">{{ $t("nav.home") }}</LocalizedLink>
            </li>
            <li class="nav-item">
              <LocalizedLink to="/projects" class="nav-link">{{ $t("nav.projects") }}</LocalizedLink>
            </li>
            <li class="nav-item">
              <LocalizedLink to="/team" class="nav-link">{{ $t("nav.team") }}</LocalizedLink>
            </li>
            <li class="nav-item">
              <LocalizedLink to="/sponsor" class="nav-link">{{ $t("nav.sponsors") }}</LocalizedLink>
            </li>
            <li class="nav-item">
              <LocalizedLink to="/about" class="nav-link">{{ $t("nav.organization") }}</LocalizedLink>
            </li>
            <li class="nav-item">
              <LocalizedLink to="/participate" class="nav-link">{{ $t("nav.participate") }}</LocalizedLink>     
            </li>
          </ul>
          <form class="form-inline my-2 my-lg-0">
          <LocaleSwitcher />
        </form>
        </div>  
      </div>
    </nav>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher"
import LocalizedLink from "@/components/LocalizedLink"

export default {
  components: { LocaleSwitcher, LocalizedLink }
}
</script>
<style scoped>
a {
  font-weight: bold;
}
</style>