const numberFormats = {
  en: {
    currency: { style: "currency", currency: "USD" }
  },
  no: {
    currency: { style: "currency", currency: "NOK" }
  },
  uk: {
    currency: { style: "currency", currency: "UAH" }
  }
}

export default numberFormats
