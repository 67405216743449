<template>
  <div class="dropdown">
    <select :value="$i18n.locale" @change.prevent="changeLocale" class="btn btn-light btn-sm dropdown-toggle">
      <option
        :key="locale.code" v-for="locale in locales" :value="locale.code" class="dropdown-item" >
        {{locale.name}}
      </option>
    </select>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales"

export default {
  name: "LocaleSwitcher",
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    changeLocale(e) {
      const locale = e.target.value
      var routeTo = `/${locale}/`
      routeTo += this.$router.currentRoute.name == "home" ? "" : this.$router.currentRoute.name
      this.$router.push(routeTo)
    }
  }
}

</script> 

<style scoped>
  li {
    text-decoration: underline;
    color: #459CE7;
    cursor: pointer;
  }
</style>